.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mt-4 {
    margin-top: 4rem;
}

.mt-5 {
    margin-top: 5rem;
}

.mt-6 {
    margin-top: 6rem;
}

.mt-7 {
    margin-top: 7rem;
}

.mt-8 {
    margin-top: 8rem;
}

.mt-9 {
    margin-top: 9rem;
}

.mt-10 {
    margin-top: 10rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.ml-4 {
    margin-left: 4rem;
}

.ml-5 {
    margin-left: 5rem;
}

.ml-6 {
    margin-left: 6rem;
}

.ml-7 {
    margin-left: 7rem;
}

.ml-8 {
    margin-left: 8rem;
}

.ml-9 {
    margin-left: 9rem;
}

.ml-10 {
    margin-left: 10rem;
}


.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #fff;
}



.app-header-text {

    display: block;
    margin: auto;
    height: 100%;
    color: #4f1794;
    font-size: large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    align-items: center;
    justify-content: center;

}

.login-background {
    background-image: url("../imgs/bg.jpg");
    background-size: cover;


}

.login-container {

    align-items: center;
    /* background-color: white; */
    background-color: rgba(0, 0, 0, .2);
    color: #fff;

    width: 50%;
    height: 70%;
    box-shadow: 0px 0px 10px black;
    border-radius: 5px;
    display: flex;
    /* margin : 0 0 0 20%; */

}


.login-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


}

.login-form-header {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    color: #389bff;
    font-weight: bold;
    margin-bottom: 1rem;
    align-items: flex-start;
}

.login-form-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.login-form-input-icon {
    width: 1rem;
    height: 1rem;
    align-items: flex-start;
    margin-right: 1rem;

}

.login-form-input {

    border-radius: 5px;
    background-color: #E5E5E5;
    padding: 0.5rem;
    font-size: 1rem;
    align-items: flex-start;
    font-family: Arial, Helvetica, sans-serif;


}

.navbar-text {
    /* color: "#fff"; */
    /* font-size: 1.5rem; */
    /* font-weight: bold; */
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    /* margin-left: 1rem; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.navbar-heading {
    color: "#fff";
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-left: 1rem;
}

.navbar-subHeading {
    color: "#fff";
    font-size: 1rem;
    /* font-weight: bold; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-left: 2rem;
}

/* .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
} */

.row {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 20px;
    align-items: center;
    justify-content: center;

}

.col-md-4 {
    flex: 1;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
}

.col-md-8 {
    flex: 1;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
}

.col-md-12 {
    flex: 1;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 240px;

}

.sidebar-logout-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 240px;
}

.main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
}

.home-heading {
    font-size: 2rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1rem;
    align-items: flex-start;
}

.home-subheading {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1rem;
    align-items: flex-start;
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1;
    /* padding: 0 20px; */
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
}

.select-container {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.select-container-classSection {
    margin-left: 5.5rem;
}

.addstudent-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-left: 11rem;
    margin-bottom: 2rem;

}

.addstudent-loader {

    padding-left: 25rem;
    margin-top: 40%;

}

.attendance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    /* margin-bottom: 2rem; */
    /* flex: 1; */
    width: 100%;

    height: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}


.attendance-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    /* margin-bottom: 2rem; */
}

.attendance-header-left {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1rem;
    align-items: flex-start;
}

.attendance-header-right {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-bottom: 1rem;
    align-items: flex-end;
}

.file-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    /* margin-bottom: 2rem; */
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
}

.select-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
}

.upload-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
}

.student-name-select-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 11rem; */
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
}

@media screen and (max-width: 600px) {
    .sidebar-container {
        display: none;
    }

    .app-header-text {
        font-size: smaller
    }

    .login-container {
        width: 100%;
        height: 100%;
    }

}